import React, { useContext, useState } from "react"
import Page from '../../components/Page'
import Layout from "../../components/Layout"
import { useStoryblokState, storyblokEditable } from "gatsby-source-storyblok"
import SideMenu from "../../components/SideMenu"
import "../../components/SideMenu/sidemenu.scss";
import "./service-solutions.scss"
import { getActiveLanguage } from "../../utils/helper"
import DataContext from "../../context/dataContext"
import GetinTouch from "../../components/GetinTouch"

export default function ServicesSolutionPage({ pageContext, location }) {
  const story = useStoryblokState(pageContext.story, location)
  const { showPopup , lang} = useContext(DataContext)
  const extra_content = story?.content?.get_in_touch_section ? (
    <GetinTouch
      lang={lang}
      setShow={showPopup}
    />
  ) : null
  return (
    <Layout location={location} full_slug={story?.full_slug} lang={story?.lang} extra_class='innerGetintouch' >
      <div className="solutionPage" >
        <div class="container">
          <div className="service-solution-page">
            <SideMenu lang={getActiveLanguage(location)} content={story?.content} uuid={story?.uuid} />
            <div className="service-solution-content">
              <Page blok={story.content} type="service-solution-page" extra_classes='service-solution-page-innerWrap' location={location} uuid={story?.uuid} extra_content={extra_content}/>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}